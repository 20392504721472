import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { getAnalytics } from "firebase/analytics";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { Box } from '@mui/system';
const firebaseConfig = {
  apiKey: "AIzaSyCYCah1lFqZ7yS6k_Yq0i6Q-jN1iQzNCdI",
  authDomain: "dunkquote.firebaseapp.com",
  projectId: "dunkquote",
  storageBucket: "dunkquote.appspot.com",
  messagingSenderId: "312812283051",
  appId: "1:312812283051:web:7c39c6f938ec7ce74443ee",
  measurementId: "G-DCWXNES6EJ"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const root = ReactDOM.createRoot(document.getElementById('root'));
const db = getFirestore();
async function homePage() {
  document.documentElement.style.display = "table";
  document.body.style.display = "table-cell";
  document.body.style.verticalAlign = "middle";
  document.body.style.textAlign = "center";
  document.body.style.backgroundColor = "#ff0077";
  document.body.style.color = "white";
  root.render(
    <React.StrictMode>
       <h1 className='QuoteHead'>DunkQuote</h1>
    </React.StrictMode>
  );
  const doc1Ref = doc(db, "noofquotes", "noofquotes");
  const doc1Snap = await getDoc(doc1Ref);
  if (doc1Snap.exists()) {
    console.log("Document data:", doc1Snap.data());
    const noOfData = doc1Snap.data().no;
    console.log(Number(noOfData));
    var lis = []
    for(let x = 0; x <= (Number(noOfData) - 1); x++) {
      const doc2Ref = doc(db, "allquotes", "allquotes");
      const doc2Snap = await getDoc(doc2Ref);
      if (doc2Snap.exists()) {
        console.log("Document data:", doc2Snap.data());
        const quoteName = doc2Snap.data()["q" + (x + 1).toString()];
        console.log("Quote name: " + quoteName);
        const doc3Ref = doc(db, "quotes", quoteName);
        const doc3Snap = await getDoc(doc3Ref);
        if (doc3Snap.exists()) {
          console.log("Document data:", doc3Snap.data());
          const title = doc3Snap.data().title;
          console.log("The title is " + title);
          const by = doc3Snap.data().by;
          const dop = doc3Snap.data().dop;
          lis.push(
            <div id={"quote" + x} className="QuoteBox">
              <MoreVertOutlinedIcon className='CardOptions dropbtn' fontSize='medium' style={{float:"right"}} onClick={function myFunction() {
  document.getElementById("drop" + (x.toString())).classList.toggle("show");
}
}></MoreVertOutlinedIcon>
                <div className="dropdown">
  <div id={"drop" + (x.toString())} className="dropdown-content">
    <a href="#" onClick={function report(e) {

    }}>Report</a>
    <a href="#" onClick={function details(e) {
       document.getElementById((x.toString() + "DialogBox")).style.display = "block";
    }}>Details</a>
    <a href="#" onClick={function(e) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
          }
        }
    }}>Close</a>
  </div>
</div>
<div id={(x.toString() + "DialogBox")} class="modal">
  <div class="modal-content">
    <div class="modal-header">
    <span class="close" style={{fontSize:"24px"}} onClick={function(e) {
       document.getElementById((x.toString() + "DialogBox")).style.display = "none";
    }}>&times;</span>
    <h2>Quote Details</h2>
  </div>
  <div class="modal-body">
    <p style={{color:"black", fontSize:"24px"}}><u>Basic Details</u></p>
    <p style={{color:"black", fontSize:"24px"}}>Title of the Quote:- {title}</p>
    <p style={{color:"black", fontSize:"24px"}}>Quote is by:- {by}</p>
    <p style={{color:"black", fontSize:"24px"}}>Special Name of the quote:- <b>{quoteName}</b></p>
    <p style={{color:"red", fontSize:"24px"}}><b>NOTICE:- </b>Special name may be used in the DunkQuote-CLI to create new <b>DunkQuotes</b>.</p>
    <p style={{color:"black", fontSize:"24px"}}>Date of Publish of the Quote:- <b>{dop}</b></p>
    <p style={{color:"black", fontSize:"24px"}}></p>
  </div>
  <div class="modal-footer">
    
  </div>
  </div>

</div>
                <h1 id={"QuoteTitle" + x} className="QuoteHead"><i>{"''" + title + "''"}</i></h1>
                <p style={{fontSize:"26px"}} className='SmallTextForBy'><i><b>- {by}</b></i></p>
            </div>
          );
          document.documentElement.style.display = "block";
  document.body.style.display = "block";
  document.body.style.verticalAlign = "none";
  document.body.style.textAlign = "center";
  document.body.style.backgroundColor = "white";
  document.body.style.color = "black";
          
        }else{
           console.log("No such document! (Error 3)");
           alert("Server side error!");
        }
       }else{
         console.log("No such document! (Error 2)");
         alert("Server side error!");
      }
    }
    root.render(
      <React.StrictMode>
         <h1 style={{textAlign:"center"}} className="QuoteHead">DunkQuote</h1>
         <hr></hr>
         <br></br>
         <br></br>
                        {lis}
                        <br></br>
                        <br></br>
        <div className='BottomNavigationBar'>
         
        </div>                               
      </React.StrictMode>
    )
  } else {
    console.log("No such document! (Error 1)");
    alert("Server side error!");
  }
}
 homePage();
 window.onclick = function(event) {
  if (!event.target.matches('.dropbtn')) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        openDropdown.classList.remove('show');
      }
    }
  }
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
